
import store from '@/store'

export default {
    inserted (el, binding, vnode) {
        const permissions = store.getters && store.getters.permissions
        const { arg, value, modifiers } = binding
        const hasPermissions = permissions.some(permission => {
            return value.includes(permission)
        })

        if (!hasPermissions) {
            el.remove()
            return false
        }

        return true
    }




}
