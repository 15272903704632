import request from '@/utils/request'
import Vue from 'vue'

/**
 * 文件列表
 * @param {page} 1 页码
 * @param {limit} 20 每页显示数量
 * @param {filter} {"genderdata":"1","status":"1"} 检索值（json字符串）
 * @param {op} {"genderdata":"=","status":"="} 检索条件（json字符串）（=，>,<,like）
 * @param {sort} weigh 排序字段
 * @param {order} desc 排序类型（降序：desc，升序：asc）
 * @returns
 */
export function apiFilesIndex (params) {
    return request({
        url: Vue.prototype.$api.file.index,
        method: 'get',
        params
    })
}

/**
 * 文件删除
 * @param {id} 1
 * @returns
 */
export function apiFilesDelete (params) {
    return request({
        url: Vue.prototype.$api.file.delete,
        method: 'post',
        params
    })
}


// 查询文件分组
export function getGroupSearch (params) {
    return request({
        url: Vue.prototype.$api.file.groupIndex,
        method: 'get',
        params
    })
}

// 查询文件分组详细
export function getGroup (data) {
    return request({
        url: Vue.prototype.$api.file.groupFind,
        method: 'post',
        data
    })
}
export function getGroupSelect (params) {
    return request({
        url: Vue.prototype.$api.file.groupSelect,
        method: 'get',
        params
    })
}

// 新增文件分组
export function addGroup (data) {
    return request({
        url: Vue.prototype.$api.file.groupAdd,
        method: 'post',
        data: data
    })
}

// 修改文件分组
export function updateGroup (data) {
    return request({
        url: Vue.prototype.$api.file.groupEdit,
        method: 'post',
        data: data
    })
}

export function deleteGroup (params) {
    return request({
        url: Vue.prototype.$api.file.groupDelete,
        method: 'post',
        params
    })
}

// 查询操作日志列表
export function getImageList (params) {
    return request({
        url: Vue.prototype.$api.file.getImageList,
        method: 'get',
        params
    })
}
export function uploadRead (data) {
    return request({
        url: Vue.prototype.$api.file.uploadRead,
        method: 'post',
        data: data
    })
}

export function uploadReads (data) {
    return request({
        url: Vue.prototype.$api.file.uploadReads,
        method: 'post',
        data: data
    })
}
// 修改文件分组
export function uploadFinish (data) {
    return request({
        url: Vue.prototype.$api.file.uploadFinish,
        method: 'post',
        data: data
    })
}

