var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片库",
            width: "68%",
            visible: _vm.showSearch,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSearch = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "space-between" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showSearch,
                              expression: "showSearch",
                            },
                          ],
                          ref: "queryForm",
                          attrs: {
                            model: _vm.queryParams,
                            inline: true,
                            "label-width": "70px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分组", prop: "group_id.value" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择分组",
                                    size: "small",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.queryParams.group_id.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams.group_id,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.group_id.value",
                                  },
                                },
                                _vm._l(_vm.folderOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "图片名称",
                                prop: "original_name.value",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "240px" },
                                attrs: {
                                  placeholder: "请输入图片名称",
                                  clearable: "",
                                  size: "small",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleQuery($event)
                                  },
                                },
                                model: {
                                  value: _vm.queryParams.original_name.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryParams.original_name,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "queryParams.original_name.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                    size: "mini",
                                  },
                                  on: { click: _vm.handleQuery },
                                },
                                [_vm._v("搜索 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh",
                                    size: "mini",
                                  },
                                  on: { click: _vm.resetQuery },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "5px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-upload",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.uploadOpen = true
                              },
                            },
                          },
                          [_vm._v(" 上传图片 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dataList.length > 0
            ? _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                _vm._l(_vm.dataList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: { margin: "5px 5px" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelectImage(item)
                        },
                      },
                    },
                    [
                      _vm.selectImage.length > 0 &&
                      _vm.selectImage.indexOf(item.url) > -1
                        ? _c(
                            "div",
                            { staticClass: "close" },
                            [_c("el-icon", { staticClass: "el-icon-check" })],
                            1
                          )
                        : _vm._e(),
                      _c("el-image", {
                        staticStyle: {
                          width: "80px",
                          height: "80px",
                          "border-radius": "5px",
                        },
                        attrs: { lazy: "", src: item.url, fit: "cover" },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "wrap",
                    "justify-content": "center",
                  },
                },
                [_c("el-empty", { attrs: { description: "暂无图片" } })],
                1
              ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.pageInfo.page,
              limit: _vm.pageInfo.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pageInfo, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.pageInfo, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.uploadTitle,
            visible: _vm.uploadOpen,
            width: "500px",
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadOpen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择分组", size: "small" },
                      model: {
                        value: _vm.group_id,
                        callback: function ($$v) {
                          _vm.group_id = $$v
                        },
                        expression: "group_id",
                      },
                    },
                    _vm._l(_vm.folderOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择图片" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        accept: ".jpg,.jpeg,.png,.webp,.bmp,.gif",
                        "before-upload": function (file) {
                          return _vm.beforeUploadVal(file, "image")
                        },
                        "list-type": "picture",
                        "show-file-list": false,
                        action: _vm.actionUrl,
                        data: _vm.uploadData,
                        multiple: true,
                        "on-exceed": _vm.onExceed,
                        "on-success": function (response, file) {
                          return _vm.onSuccessUpload(response, file, "image")
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-upload",
                            loading: _vm.uploadBtn.load,
                            disabled: _vm.uploadBtn.load,
                            type: "primary",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.uploadBtn.text) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.uploadOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频库",
            width: "68%",
            visible: _vm.showVideoSearch,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVideoSearch = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "space-between" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showVideoSearch,
                              expression: "showVideoSearch",
                            },
                          ],
                          ref: "queryVideoForm",
                          attrs: {
                            model: _vm.queryVideoParams,
                            inline: true,
                            "label-width": "70px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分组", prop: "group_id.value" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择分组",
                                    size: "small",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.queryVideoParams.group_id.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryVideoParams.group_id,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryVideoParams.group_id.value",
                                  },
                                },
                                _vm._l(_vm.folderVideoOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "视频名称",
                                prop: "original_name.value",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "240px" },
                                attrs: {
                                  placeholder: "请输入视频名称",
                                  clearable: "",
                                  size: "small",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleVideoQuery($event)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.queryVideoParams.original_name.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryVideoParams.original_name,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "queryVideoParams.original_name.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                    size: "mini",
                                  },
                                  on: { click: _vm.handleVideoQuery },
                                },
                                [_vm._v("搜索 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh",
                                    size: "mini",
                                  },
                                  on: { click: _vm.resetVideoQuery },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "5px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-upload",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.uploadVideoOpen = true
                              },
                            },
                          },
                          [_vm._v(" 上传视频 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dataVideoList.length > 0
            ? _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                _vm._l(_vm.dataVideoList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: { margin: "5px 5px" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelectVideo(item)
                        },
                      },
                    },
                    [
                      _vm.selectVideo.length > 0 &&
                      _vm.selectVideo.indexOf(item.url) > -1
                        ? _c(
                            "div",
                            { staticClass: "close" },
                            [_c("el-icon", { staticClass: "el-icon-check" })],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "play" },
                        [
                          _c("el-icon", {
                            staticClass: "el-icon-video-camera",
                          }),
                        ],
                        1
                      ),
                      _c("el-image", {
                        staticStyle: {
                          width: "80px",
                          height: "80px",
                          "border-radius": "5px",
                        },
                        attrs: {
                          lazy: "",
                          src:
                            item.url + "?x-oss-process=video/snapshot,t_1000",
                          fit: "cover",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "wrap",
                    "justify-content": "center",
                  },
                },
                [_c("el-empty", { attrs: { description: "暂无视频" } })],
                1
              ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.videoTotal > 0,
                expression: "videoTotal > 0",
              },
            ],
            attrs: {
              total: _vm.videoTotal,
              page: _vm.pageVideoInfo.page,
              limit: _vm.pageVideoInfo.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pageVideoInfo, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.pageVideoInfo, "limit", $event)
              },
              pagination: _vm.getVideoList,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onVideoCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onVideoSure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.uploadVideoTitle,
            visible: _vm.uploadVideoOpen,
            width: "500px",
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadVideoOpen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择分组", size: "small" },
                      model: {
                        value: _vm.group_video_id,
                        callback: function ($$v) {
                          _vm.group_video_id = $$v
                        },
                        expression: "group_video_id",
                      },
                    },
                    _vm._l(_vm.folderVideoOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择视频" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        accept: "video/*",
                        "before-upload": function (file) {
                          return _vm.beforeUploadVal(file, "video")
                        },
                        "list-type": "picture",
                        "show-file-list": false,
                        action: _vm.actionUrl,
                        data: _vm.uploadData,
                        multiple: false,
                        "on-success": function (response, file) {
                          return _vm.onSuccessUpload(response, file, "video")
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-upload",
                            loading: _vm.uploadBtn.load,
                            disabled: _vm.uploadBtn.load,
                            type: "primary",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.uploadBtn.text) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.uploadVideoOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "语音库",
            width: "68%",
            "destroy-on-close": true,
            visible: _vm.showAudioSearch,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAudioSearch = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "space-between" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showAudioSearch,
                              expression: "showAudioSearch",
                            },
                          ],
                          ref: "queryAudioForm",
                          attrs: {
                            model: _vm.queryAudioParams,
                            inline: true,
                            "label-width": "70px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分组", prop: "group_id.value" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择分组",
                                    size: "small",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.queryAudioParams.group_id.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryAudioParams.group_id,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryAudioParams.group_id.value",
                                  },
                                },
                                _vm._l(_vm.folderAudioOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "语音名称",
                                prop: "original_name.value",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "240px" },
                                attrs: {
                                  placeholder: "请输入语音名称",
                                  clearable: "",
                                  size: "small",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleAudioQuery($event)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.queryAudioParams.original_name.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryAudioParams.original_name,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "queryAudioParams.original_name.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                    size: "mini",
                                  },
                                  on: { click: _vm.handleAudioQuery },
                                },
                                [_vm._v("搜索 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh",
                                    size: "mini",
                                  },
                                  on: { click: _vm.resetAudioQuery },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "5px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-upload",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.uploadAudioOpen = true
                              },
                            },
                          },
                          [_vm._v(" 上传语音 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dataAudioList.length > 0
            ? _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.dataAudioList },
                      on: {
                        "selection-change": function (selection) {
                          return _vm.handleSelectionChange(selection, "audio")
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          align: "center",
                          width: "55",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "url",
                          align: "center",
                          label: "文件地址",
                          width: "700",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "original_name",
                          align: "center",
                          label: "文件原名称",
                          "min-width": "200",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "文件大小",
                          align: "center",
                          prop: "file_size",
                          width: "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          scope.row.file_size /
                                          1024 /
                                          1024
                                        ).toFixed(2)
                                      ) +
                                      "M "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3060931901
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "文件后缀",
                          align: "center",
                          prop: "file_ext",
                          width: "120",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "wrap",
                    "justify-content": "center",
                  },
                },
                [_c("el-empty", { attrs: { description: "暂无语音" } })],
                1
              ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.audioTotal > 0,
                expression: "audioTotal > 0",
              },
            ],
            attrs: {
              total: _vm.audioTotal,
              page: _vm.pageAudioInfo.page,
              limit: _vm.pageAudioInfo.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pageAudioInfo, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.pageAudioInfo, "limit", $event)
              },
              pagination: _vm.getAudioList,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onAudioCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onAudioSure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.uploadAudioTitle,
            "destroy-on-close": true,
            visible: _vm.uploadAudioOpen,
            width: "500px",
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadAudioOpen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择分组", size: "small" },
                      model: {
                        value: _vm.group_audio_id,
                        callback: function ($$v) {
                          _vm.group_audio_id = $$v
                        },
                        expression: "group_audio_id",
                      },
                    },
                    _vm._l(_vm.folderAudioOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择文件" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        accept: ".mp3,.wav",
                        "before-upload": function (file) {
                          return _vm.beforeUploadVal(file, "audio")
                        },
                        "list-type": "picture",
                        "show-file-list": false,
                        action: _vm.actionUrl,
                        data: _vm.uploadData,
                        multiple: false,
                        "on-success": function (response, file) {
                          return _vm.onSuccessUpload(response, file, "audio")
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-upload",
                            loading: _vm.uploadBtn.load,
                            disabled: _vm.uploadBtn.load,
                            type: "primary",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.uploadBtn.text) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.uploadAudioOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "文档库",
            width: "68%",
            "destroy-on-close": true,
            visible: _vm.showWordSearch,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showWordSearch = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "space-between" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showWordSearch,
                              expression: "showWordSearch",
                            },
                          ],
                          ref: "queryWordForm",
                          attrs: {
                            model: _vm.queryWordParams,
                            inline: true,
                            "label-width": "70px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分组", prop: "group_id.value" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择分组",
                                    size: "small",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.queryWordParams.group_id.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryWordParams.group_id,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "queryWordParams.group_id.value",
                                  },
                                },
                                _vm._l(_vm.folderWordOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "文档名称",
                                prop: "original_name.value",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "240px" },
                                attrs: {
                                  placeholder: "请输入语音名称",
                                  clearable: "",
                                  size: "small",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleWordQuery($event)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.queryWordParams.original_name.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.queryWordParams.original_name,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "queryWordParams.original_name.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                    size: "mini",
                                  },
                                  on: { click: _vm.handleWordQuery },
                                },
                                [_vm._v("搜索 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-refresh",
                                    size: "mini",
                                  },
                                  on: { click: _vm.resetWordQuery },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "5px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-upload",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.uploadWordOpen = true
                              },
                            },
                          },
                          [_vm._v(" 上传文档 ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dataWordList.length > 0
            ? _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.dataWordList },
                      on: {
                        "selection-change": function (selection) {
                          return _vm.handleSelectionChange(selection, "word")
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          align: "center",
                          width: "55",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "url",
                          align: "center",
                          label: "文件地址",
                          width: "700",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "original_name",
                          align: "center",
                          label: "文件原名称",
                          "min-width": "200",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "文件大小",
                          align: "center",
                          prop: "file_size",
                          width: "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        (
                                          scope.row.file_size /
                                          1024 /
                                          1024
                                        ).toFixed(2)
                                      ) +
                                      "M "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3060931901
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "文件后缀",
                          align: "center",
                          prop: "file_ext",
                          width: "120",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "wrap",
                    "justify-content": "center",
                  },
                },
                [_c("el-empty", { attrs: { description: "暂无文档" } })],
                1
              ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.wordTotal > 0,
                expression: "wordTotal > 0",
              },
            ],
            attrs: {
              total: _vm.wordTotal,
              page: _vm.pageWordInfo.page,
              limit: _vm.pageWordInfo.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.pageWordInfo, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.pageWordInfo, "limit", $event)
              },
              pagination: _vm.getWordList,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onWordCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onWordSure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.uploadWordTitle,
            "destroy-on-close": true,
            visible: _vm.uploadWordOpen,
            width: "500px",
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadWordOpen = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择分组", size: "small" },
                      model: {
                        value: _vm.group_word_id,
                        callback: function ($$v) {
                          _vm.group_word_id = $$v
                        },
                        expression: "group_word_id",
                      },
                    },
                    _vm._l(_vm.folderWordOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择文件" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        accept:
                          ".doc,.xlsx,.xls,.pdf,.docx,.rar,.zip,.ppt,.pptx",
                        "before-upload": function (file) {
                          return _vm.beforeUploadVal(file, "word")
                        },
                        "list-type": "picture",
                        "show-file-list": false,
                        action: _vm.actionUrl,
                        data: _vm.uploadData,
                        multiple: false,
                        "on-success": function (response, file) {
                          return _vm.onSuccessUpload(response, file, "word")
                        },
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            icon: "el-icon-upload",
                            loading: _vm.uploadBtn.load,
                            disabled: _vm.uploadBtn.load,
                            type: "primary",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.uploadBtn.text) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.uploadWordOpen = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { ref: "editor", staticClass: "editor", style: _vm.styles }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }