import Quill from 'quill'

const BlockEmbed = Quill.import('blots/block')
const Link = Quill.import('formats/link')

class QuillAudio extends BlockEmbed {
    static create(value) {
        const node = super.create(value)
        node.setAttribute('target', '_blank');
        // node.setAttribute('rel', 'noopener noreferrer');
        node.setAttribute('href', this.sanitize(value))
        node.innerHTML = this.sanitize(value);
        // let p = document.createElement("p");
        // p.appendChild(node);
        return node;
    }

    static sanitize(url) {
        return Link.sanitize(url)
    }

    static value(domNode) {
        return domNode.getAttribute('href')
    }

    format(name, value) {
        super.format(name, value)
    }

}

QuillAudio.blotName = 'word'
QuillAudio.className = 'ql-word'
QuillAudio.tagName = 'a'

export default QuillAudio
