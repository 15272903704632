<template>
    <div>
        <!--上传图片-->
        <el-dialog
            title="图片库"
            width="68%"
            :visible.sync="showSearch"
            :close-on-click-modal="false"
            append-to-body>
            <div>
                <el-row type="flex" justify="space-between">
                    <el-col :span="18">
                        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch"
                                 label-width="70px">
                            <el-form-item label="分组" prop="group_id.value">
                                <el-select v-model="queryParams.group_id.value" placeholder="请选择分组" size="small" clearable>
                                    <el-option
                                        v-for="item in folderOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="图片名称" prop="original_name.value">
                                <el-input v-model="queryParams.original_name.value" placeholder="请输入图片名称" clearable
                                          size="small"
                                          style="width: 240px" @keyup.enter.native="handleQuery"/>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索
                                </el-button>
                                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="2">
                        <div style="margin-top: 5px">
                            <el-button size="mini" icon="el-icon-upload" @click="uploadOpen = true" type="primary">
                                上传图片
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="display: flex; flex-wrap: wrap;" v-if="dataList.length > 0">
                <div style="margin: 5px 5px;"
                     v-for="(item, index) in dataList"
                     :key="index"
                     @click="onSelectImage(item)">
                    <div v-if="selectImage.length > 0 && selectImage.indexOf(item.url) > -1"
                         class="close">
                        <el-icon class="el-icon-check"/>
                    </div>
                    <el-image
                        style="width: 80px; height: 80px; border-radius: 5px; "
                        lazy
                        :src="item.url"
                        fit="cover">
                    </el-image>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap; justify-content: center" v-else>
                <el-empty description="暂无图片"></el-empty>
            </div>
            <pagination v-show="total > 0" :total="total" :page.sync="pageInfo.page" :limit.sync="pageInfo.limit"
                        @pagination="getList"/>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="onCancel">取 消</el-button>
                    <el-button type="primary" @click="onSure">确 定</el-button>
                </span>
        </el-dialog>
        <el-dialog :title="uploadTitle" :visible.sync="uploadOpen" width="500px" :close-on-click-modal="false" append-to-body>
            <el-form label-width="80px">
                <el-form-item label="分组" >
                    <el-select v-model="group_id" placeholder="请选择分组" size="small">
                        <el-option
                            v-for="item in folderOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择图片">
                    <el-upload
                        class="upload-demo"
                        accept=".jpg,.jpeg,.png,.webp,.bmp,.gif"
                        :before-upload="(file) => beforeUploadVal(file, 'image')"
                        list-type="picture"
                        :show-file-list="false"
                        :action="actionUrl"
                        :data="uploadData"
                        :multiple="true"
                        :on-exceed="onExceed"
                        :on-success="(response, file) => onSuccessUpload(response, file, 'image')">
                        <el-button size="mini" icon="el-icon-upload"
                                   :loading="uploadBtn.load" :disabled="uploadBtn.load" type="primary">
                            {{ uploadBtn.text }}
                        </el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="uploadOpen = false">取 消</el-button>
            </div>
        </el-dialog>

        <!--上传视频-->
        <el-dialog
            title="视频库"
            width="68%"
            :visible.sync="showVideoSearch"
            :close-on-click-modal="false"
            append-to-body>
            <div>
                <el-row type="flex" justify="space-between">
                    <el-col :span="18">
                        <el-form :model="queryVideoParams" ref="queryVideoForm" :inline="true" v-show="showVideoSearch"
                                 label-width="70px">
                            <el-form-item label="分组" prop="group_id.value">
                                <el-select v-model="queryVideoParams.group_id.value" placeholder="请选择分组" size="small" clearable>
                                    <el-option
                                        v-for="item in folderVideoOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="视频名称" prop="original_name.value">
                                <el-input v-model="queryVideoParams.original_name.value" placeholder="请输入视频名称" clearable
                                          size="small"
                                          style="width: 240px" @keyup.enter.native="handleVideoQuery"/>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleVideoQuery">搜索
                                </el-button>
                                <el-button icon="el-icon-refresh" size="mini" @click="resetVideoQuery">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="2">
                        <div style="margin-top: 5px">
                            <el-button size="mini" icon="el-icon-upload" @click="uploadVideoOpen = true" type="primary">
                                上传视频
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="display: flex; flex-wrap: wrap;" v-if="dataVideoList.length > 0">
                <div style="margin: 5px 5px;"
                     v-for="(item, index) in dataVideoList"
                     :key="index"
                     @click="onSelectVideo(item)">
                    <div v-if="selectVideo.length > 0 && selectVideo.indexOf(item.url) > -1"
                         class="close">
                        <el-icon class="el-icon-check"/>
                    </div>
                    <div class="play">
                        <el-icon class="el-icon-video-camera"/>
                    </div>
                    <el-image
                        style="width: 80px; height: 80px; border-radius: 5px; "
                        lazy
                        :src="`${item.url}?x-oss-process=video/snapshot,t_1000`"
                        fit="cover">
                    </el-image>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap; justify-content: center" v-else>
                <el-empty description="暂无视频"></el-empty>
            </div>
            <pagination v-show="videoTotal > 0" :total="videoTotal" :page.sync="pageVideoInfo.page" :limit.sync="pageVideoInfo.limit"
                        @pagination="getVideoList"/>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="onVideoCancel">取 消</el-button>
                    <el-button type="primary" @click="onVideoSure">确 定</el-button>
                </span>
        </el-dialog>
        <el-dialog :title="uploadVideoTitle" :visible.sync="uploadVideoOpen" width="500px" :close-on-click-modal="false" append-to-body>
            <el-form label-width="80px">
                <el-form-item label="分组" >
                    <el-select v-model="group_video_id" placeholder="请选择分组" size="small">
                        <el-option
                            v-for="item in folderVideoOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择视频">
                    <el-upload
                        class="upload-demo"
                        accept="video/*"
                        :before-upload="(file) => beforeUploadVal(file, 'video')"
                        list-type="picture"
                        :show-file-list="false"
                        :action="actionUrl"
                        :data="uploadData"
                        :multiple="false"
                        :on-success="(response, file) => onSuccessUpload(response, file, 'video')">
                        <el-button size="mini" icon="el-icon-upload"
                                   :loading="uploadBtn.load" :disabled="uploadBtn.load" type="primary">
                            {{ uploadBtn.text }}
                        </el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="uploadVideoOpen = false">取 消</el-button>
            </div>
        </el-dialog>

        <!--语音库-->
        <el-dialog title="语音库" width="68%" :destroy-on-close="true" :visible.sync="showAudioSearch" :close-on-click-modal="false"
                   append-to-body>
            <div>
                <el-row type="flex" justify="space-between">
                    <el-col :span="16">
                        <el-form :model="queryAudioParams" ref="queryAudioForm" :inline="true" v-show="showAudioSearch" label-width="70px">
                            <el-form-item label="分组" prop="group_id.value">
                                <el-select v-model="queryAudioParams.group_id.value" placeholder="请选择分组" size="small" clearable>
                                    <el-option v-for="item in folderAudioOptions" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="语音名称" prop="original_name.value">
                                <el-input v-model="queryAudioParams.original_name.value" placeholder="请输入语音名称" clearable
                                          size="small" style="width: 240px" @keyup.enter.native="handleAudioQuery" />
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleAudioQuery">搜索
                                </el-button>
                                <el-button icon="el-icon-refresh" size="mini" @click="resetAudioQuery">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="5">
                        <div style="margin-top: 5px">
                            <el-button size="mini" icon="el-icon-upload" @click="uploadAudioOpen = true" type="primary">
                                上传语音
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="display: flex; flex-wrap: wrap;" v-if="dataAudioList.length > 0">
                <el-table v-loading="loading" :data="dataAudioList" style="width: 100%"
                          @selection-change="(selection) => handleSelectionChange(selection, 'audio')">
                    <el-table-column type="selection" align="center" width="55"></el-table-column>
                    <el-table-column prop="url" align="center" label="文件地址" width="700" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="original_name" align="center" label="文件原名称" min-width="200"
                                     :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column label="文件大小" align="center" prop="file_size" width="120">
                        <template slot-scope="scope"> {{ (scope.row.file_size / 1024 / 1024).toFixed(2) }}M </template>
                    </el-table-column>
                    <el-table-column label="文件后缀" align="center" prop="file_ext" width="120" />
                </el-table>
            </div>
            <div style="display: flex; flex-wrap: wrap; justify-content: center" v-else>
                <el-empty description="暂无语音"></el-empty>
            </div>
            <pagination v-show="audioTotal > 0" :total="audioTotal" :page.sync="pageAudioInfo.page" :limit.sync="pageAudioInfo.limit"
                        @pagination="getAudioList" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="onAudioCancel">取 消</el-button>
                <el-button type="primary" @click="onAudioSure">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :title="uploadAudioTitle" :destroy-on-close="true" :visible.sync="uploadAudioOpen" width="500px"
                   :close-on-click-modal="false" append-to-body>
            <el-form label-width="80px">
                <el-form-item label="分组">
                    <el-select v-model="group_audio_id" placeholder="请选择分组" size="small">
                        <el-option v-for="item in folderAudioOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择文件">
                    <el-upload class="upload-demo" accept=".mp3,.wav"
                               :before-upload="(file) => beforeUploadVal(file, 'audio')" list-type="picture" :show-file-list="false" :action="actionUrl"
                               :data="uploadData" :multiple="false" :on-success="(response, file) => onSuccessUpload(response, file, 'audio')">
                        <el-button size="mini" icon="el-icon-upload" :loading="uploadBtn.load" :disabled="uploadBtn.load"
                                   type="primary">
                            {{ uploadBtn.text }}
                        </el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="uploadAudioOpen = false">取 消</el-button>
            </div>
        </el-dialog>

        <!--文档-->
        <el-dialog title="文档库" width="68%" :destroy-on-close="true" :visible.sync="showWordSearch" :close-on-click-modal="false"
                   append-to-body>
            <div>
                <el-row type="flex" justify="space-between">
                    <el-col :span="16">
                        <el-form :model="queryWordParams" ref="queryWordForm" :inline="true" v-show="showWordSearch" label-width="70px">
                            <el-form-item label="分组" prop="group_id.value">
                                <el-select v-model="queryWordParams.group_id.value" placeholder="请选择分组" size="small" clearable>
                                    <el-option v-for="item in folderWordOptions" :key="item.value" :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="文档名称" prop="original_name.value">
                                <el-input v-model="queryWordParams.original_name.value" placeholder="请输入语音名称" clearable
                                          size="small" style="width: 240px" @keyup.enter.native="handleWordQuery" />
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleWordQuery">搜索
                                </el-button>
                                <el-button icon="el-icon-refresh" size="mini" @click="resetWordQuery">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="5">
                        <div style="margin-top: 5px">
                            <el-button size="mini" icon="el-icon-upload" @click="uploadWordOpen = true" type="primary">
                                上传文档
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="display: flex; flex-wrap: wrap;" v-if="dataWordList.length > 0">
                <el-table v-loading="loading" :data="dataWordList" style="width: 100%"
                          @selection-change="(selection) => handleSelectionChange(selection, 'word')">
                    <el-table-column type="selection" align="center" width="55"></el-table-column>
                    <el-table-column prop="url" align="center" label="文件地址" width="700" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="original_name" align="center" label="文件原名称" min-width="200"
                                     :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column label="文件大小" align="center" prop="file_size" width="120">
                        <template slot-scope="scope"> {{ (scope.row.file_size / 1024 / 1024).toFixed(2) }}M </template>
                    </el-table-column>
                    <el-table-column label="文件后缀" align="center" prop="file_ext" width="120" />
                </el-table>
            </div>
            <div style="display: flex; flex-wrap: wrap; justify-content: center" v-else>
                <el-empty description="暂无文档"></el-empty>
            </div>
            <pagination v-show="wordTotal > 0" :total="wordTotal" :page.sync="pageWordInfo.page" :limit.sync="pageWordInfo.limit"
                        @pagination="getWordList" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="onWordCancel">取 消</el-button>
                <el-button type="primary" @click="onWordSure">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :title="uploadWordTitle" :destroy-on-close="true" :visible.sync="uploadWordOpen" width="500px"
                   :close-on-click-modal="false" append-to-body>
            <el-form label-width="80px">
                <el-form-item label="分组">
                    <el-select v-model="group_word_id" placeholder="请选择分组" size="small">
                        <el-option v-for="item in folderWordOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择文件">
                    <el-upload class="upload-demo" accept=".doc,.xlsx,.xls,.pdf,.docx,.rar,.zip,.ppt,.pptx"
                               :before-upload="(file) => beforeUploadVal(file, 'word')"
                               list-type="picture"
                               :show-file-list="false"
                               :action="actionUrl"
                               :data="uploadData"
                               :multiple="false" :on-success="(response, file) => onSuccessUpload(response, file, 'word')">
                        <el-button size="mini" icon="el-icon-upload" :loading="uploadBtn.load" :disabled="uploadBtn.load"
                                   type="primary">
                            {{ uploadBtn.text }}
                        </el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="uploadWordOpen = false">取 消</el-button>
            </div>
        </el-dialog>

        <div class="editor" ref="editor" :style="styles"></div>
    </div>
</template>

<script>
import {getImageList, uploadRead, uploadFinish, getGroupSelect} from '@/api/system/file'
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Audio from "./quillaudio";
import Video from "./quillvideo";
import Word from "./quillword";
import { getToken } from '@/utils/auth'
Quill.register(Audio, true);
Quill.register(Video, true);
Quill.register(Word, true);

export default {
    name: 'Editor',
    props: {
        multipleChoice: {
            type: Boolean,
            default: true
        },
        maxChoice: {
            type: Number,
            default: 10
        },
        /* 编辑器的内容 */
        value: {
            type: String,
            default: '',
        },
        /* 高度 */
        height: {
            type: Number,
            default: null,
        },
        /* 最小高度 */
        minHeight: {
            type: Number,
            default: 300,
        },
        /* 只读 */
        readOnly: {
            type: Boolean,
            default: false,
        },
        // 上传文件大小限制(MB)
        fileSize: {
            type: Number,
            default: 5,
        },
        /* 类型（base64格式、url格式） */
        type: {
            type: String,
            default: 'url',
        },
    },
    data() {
        return {
            loading: false,
            dataList: [],
            dataVideoList: [],
            dataAudioList: [],
            dataWordList: [],
            // 显示搜索条件
            showSearch: false,
            showVideoSearch: false,
            showAudioSearch: false,
            showWordSearch: false,
            // 查询参数
            queryParams: {
                original_name: {value: undefined, op: 'like'},
                file_type: {value: 'image', op: '='},
                group_id: {value: undefined, op: '='},
            },
            queryVideoParams: {
                original_name: {value: undefined, op: 'like'},
                file_type: {value: 'video', op: '='},
                group_id: {value: undefined, op: '='},
            },

            queryAudioParams: {
                original_name: {value: undefined, op: 'like'},
                file_type: {value: 'audio', op: '='},
                group_id: {value: undefined, op: '='},
            },
            queryWordParams: {
                original_name: {value: undefined, op: 'like'},
                file_type: {value: 'file', op: '='},
                group_id: {value: undefined, op: '='},
            },
            uploadFile: [],
            actionUrl: '',
            uploadBtn: {
                text: '点击上传',
                load: false,
            },
            uploadData: {},
            uploadKey: '',
            // 总条数
            total: 0,
            videoTotal: 0,
            audioTotal: 0,
            wordTotal: 0,
            pageInfo: {
                page: 1,
                limit: 50,
                sort: undefined,
                order: undefined,
            },
            pageVideoInfo: {
                page: 1,
                limit: 50,
                sort: undefined,
                order: undefined,
            },
            pageAudioInfo: {
                page: 1,
                limit: 50,
                sort: undefined,
                order: undefined,
            },
            pageWordInfo: {
                page: 1,
                limit: 50,
                sort: undefined,
                order: undefined,
            },
            selectImage: [],
            selectVideo: [],
            selectAudio: [],
            selectWord: [],
            headers: {
                token: getToken(),
            },
            Quill: null,
            currentValue: '',
            options: {
                theme: 'snow',
                bounds: document.body,
                debug: 'warn',
                modules: {
                    // 工具栏配置
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video', 'audio', 'word'], // 链接、图片、视频、音频、文档
                    ],
                },
                placeholder: '请输入内容',
                readOnly: this.readOnly,
            },
            folderOptions: [],
            folderVideoOptions: [],
            folderAudioOptions: [],
            folderWordOptions: [],
            group_id: undefined,
            group_video_id: undefined,
            group_audio_id: undefined,
            group_word_id: undefined,
            uploadTitle: '上传图片',
            uploadVideoTitle: '上传视频',
            uploadAudioTitle: '上传视频',
            uploadWordTitle: '上传文档',
            uploadOpen: false,
            uploadVideoOpen: false,
            uploadAudioOpen: false,
            uploadWordOpen: false,
        }
    },
    computed: {
        styles() {
            const style = {}
            if (this.minHeight) {
                style.minHeight = `${this.minHeight}px`
            }
            if (this.height) {
                style.height = `${this.height}px`
            }
            return style
        },
    },
    watch: {
        value: {
            handler(val) {
                if (val !== this.currentValue) {
                    this.currentValue = val === null ? '' : val
                    if (this.Quill) {
                        this.Quill.pasteHTML(this.currentValue)
                    }
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.init()
        this.getGroupsData();
        this.getVideoGroupsData();
        this.getAudioGroupsData();
        this.getWordGroupsData();
    },
    beforeDestroy() {
        this.Quill = null
    },
    methods: {
        handleSelectionChange(selection, type) {
            if(type === 'audio') {
                this.selectAudio = selection.map(item => item.url)
            }else if(type === 'word') {
                this.selectWord = selection.map(item => item.url)
            }

        },

        init() {
            let self = this;
            const editor = this.$refs.editor
            this.Quill = new Quill(editor, this.options)
            const toolbar = this.Quill.getModule('toolbar');
            toolbar.addHandler('image', value => {
                if(value) {
                    self.onOpenSelectImage();
                }else {
                    this.quill.format('image', false)
                }
            })

            toolbar.addHandler('video', value => {
                if(value) {
                    self.onOpenSelectVideo();
                }else {
                    this.quill.format('video', false)
                }
            })
            toolbar.addHandler('audio', value => {
                if(value) {
                    self.onOpenSelectAuido();
                }else {
                    this.quill.format('audio', false)
                }
            })

            toolbar.addHandler('word', value => {
                if(value) {
                    self.onOpenSelectWord();
                }else {
                    this.quill.format('word', false)
                }
            })

            this.Quill.pasteHTML(this.currentValue)
            this.Quill.on('text-change', (delta, oldDelta, source) => {
                const html = this.$refs.editor.children[0].innerHTML
                const text = this.Quill.getText()
                const quill = this.Quill
                this.currentValue = html
                this.$emit('input', html)
                this.$emit('on-change', { html, text, quill })
            })
            this.Quill.on('text-change', (delta, oldDelta, source) => {
                this.$emit('on-text-change', delta, oldDelta, source)
            })
            this.Quill.on('selection-change', (range, oldRange, source) => {
                this.$emit('on-selection-change', range, oldRange, source)
            })
            this.Quill.on('editor-change', (eventName, ...args) => {
                this.$emit('on-editor-change', eventName, ...args)
            })
        },
        async onOpenSelectImage() {
            this.getList();
            this.showSearch = true;
        },

        async onOpenSelectVideo() {
            this.getVideoList();
            this.showVideoSearch = true;
        },

        async onOpenSelectAuido() {
            this.getAudioList();
            this.showAudioSearch = true;
        },

        async onOpenSelectWord() {
            this.getWordList();
            this.showWordSearch = true;
        },
        async getGroupsData () {
            const result = await getGroupSelect({type: 1});
            if(result) {
                this.folderOptions = result.data;
                this.group_id = result.data[0].value;
            }
        },

        async getVideoGroupsData () {
            const result = await getGroupSelect({type: 2});
            if(result) {
                this.folderVideoOptions = result.data;
                this.group_video_id = result.data[0].value;
            }
        },

        async getAudioGroupsData () {
            this.loading = true;
            const result = await getGroupSelect({type: 4});
            if(result) {
                this.folderAudioOptions = result.data;
                this.group_audio_id = result.data[0].value;
                this.loading = false;
            }
        },

        async getWordGroupsData () {
            this.loading = true;
            const result = await getGroupSelect({type: 3});
            if(result) {
                this.folderWordOptions = result.data;
                this.group_word_id = result.data[0].value;
                this.loading = false;
            }
        },
        onSelectImage(item) {
            let idx = this.selectImage.indexOf(item.url);
            if (idx > -1) {
                this.selectImage.splice(idx, 1);
            } else {
                if (!this.multipleChoice && this.selectImage.length >= this.maxChoice) {
                    this.msgInfo('只能选择一张');
                    return;
                }
                this.selectImage.push(item.url);
            }
        },

        onSelectVideo(item) {
            let idx = this.selectVideo.indexOf(item.url);
            if (idx > -1) {
                this.selectVideo.splice(idx, 1);
            } else {
                this.selectVideo.push(item.url);
            }
        },

        // 查询管理员列表
        async getList() {
            const qyparams = {
                ...this.pageInfo,
                ...this.formatQueryParams(this.queryParams),
            }
            const result = await getImageList(qyparams);
            if (result) {
                this.dataList = result.data
                this.total = result.count
            }
        },
        async getVideoList() {
            const qyparams = {
                ...this.pageVideoInfo,
                ...this.formatQueryParams(this.queryVideoParams),
            }
            const result = await getImageList(qyparams);
            if (result) {
                this.dataVideoList = result.data
                this.videoTotal = result.count
            }
        },

        async getAudioList() {
            const qyparams = {
                ...this.pageAudioInfo,
                ...this.formatQueryParams(this.queryAudioParams),
            }
            const result = await getImageList(qyparams);
            if (result) {
                this.dataAudioList = result.data
                this.audioTotal = result.count
            }
        },

        async getWordList() {
            const qyparams = {
                ...this.pageWordInfo,
                ...this.formatQueryParams(this.queryWordParams),
            }
            const result = await getImageList(qyparams);
            if (result) {
                this.dataWordList = result.data
                this.wordTotal = result.count
            }
        },


        handleVideoQuery() {
            this.pageVideoInfo.page = 1
            this.getVideoList()
        },

        handleAudioQuery() {
            this.pageAudioInfo.page = 1
            this.getAudioList()
        },
        handleWordQuery() {
            this.pageWordInfo.page = 1
            this.getWordList()
        },

        handleQuery() {
            this.pageInfo.page = 1
            this.getList()
        },

        onExceed(files) {
            if(files.length > 10){
                this.msgError('最多只能选择10个文件上传');
                return false;
            }
        },

        async beforeUploadVal(file, type) {
            if(type === 'image') {
                if(file.size > 1024 * 1024 * 2) {
                    this.msgError(`（${file.name}）文件不能大于2M`);
                    return false;
                }
            }

            this.uploadBtn = {
                text: '上传中',
                load: true,
            }
            const result = await uploadRead({
                file_name: file.name,
                size: file.size,
                type: file.type,
            });

            if (result && result.data) {
                const {data} = result;
                this.uploadFile.push({
                    uid: file.uid,
                    uploadKey: data.key,
                    actionUrl: data.url,
                    uploadData: data.params,
                });
                this.actionUrl = result.data.url;
                this.uploadData = result.data.params;
                return true;
            }
        },

        async onSuccessUpload(response, file, type) {
            let groupId = 0;
            if(type === 'image') {
                groupId = this.group_id;
            }else if(type === 'video') {
                groupId = this.group_video_id;
            }else if(type === 'audio') {
                groupId = this.group_audio_id;
            }else if(type === 'word') {
                groupId = this.group_word_id;
            }
            let idx = this.uploadFile.findIndex(item => {
                return item.uid === file.uid;
            });
            if(idx > -1) {
                const uploadFile = this.uploadFile[idx];
                const result = await uploadFinish({
                    key: uploadFile.uploadKey,
                    file_name: file.name,
                    url: uploadFile.uploadData.key,
                    group_id: this.group_id,
                });
                if (result) {
                    if(this.uploadFile.length - 1 === idx) {
                        this.uploadFile = [];
                        this.actionUrl = '';
                        this.uploadData = {};
                        this.uploadBtn = {
                            text: '点击上传',
                            load: false,
                        };
                        setTimeout(() => {
                            this.msgSuccess('上传成功');
                            if(type === 'image') {
                                this.uploadOpen = false;
                                this.pageInfo.page = 1;
                                this.getList();
                            }else if(type === 'video') {
                                this.uploadVideoOpen = false;
                                this.pageVideoInfo.page = 1;
                                this.getVideoList();
                            }else if(type === 'audio') {
                                this.uploadAudioOpen = false;
                                this.pageAudioInfo.page = 1;
                                this.getAudioList();
                            }else if(type === 'word') {
                                this.uploadWordOpen = false;
                                this.pageWordInfo.page = 1;
                                this.getWordList();
                            }
                        }, 500);

                    }
                }
            }else {
                this.msgError('上传失败');
            }
        },

        resetQuery() {
            this.resetForm('queryForm')
            this.handleQuery()
        },

        resetVideoQuery() {
            this.resetForm('queryVideoForm')
            this.handleVideoQuery()
        },

        resetAudioQuery() {
            this.resetForm('queryAudioForm')
            this.handleAudioQuery()
        },

        resetWordQuery() {
            this.resetForm('queryWordForm')
            this.handleWordQuery()
        },

        onCancel() {
            this.resetForm('queryForm');
            this.uploadBtn = {
                text: '点击上传',
                btn: false,
            };
            this.selectImage = [];
            this.showSearch = false
        },

        onVideoCancel() {
            this.resetForm('queryVideoForm');
            this.uploadBtn = {
                text: '点击上传',
                btn: false,
            };
            this.selectVideo = [];
            this.showVideoSearch = false
        },

        onAudioCancel() {
            this.resetForm('queryAudioForm');
            this.uploadBtn = {
                text: '点击上传',
                btn: false,
            };
            this.selectAudio = [];
            this.showAudioSearch = false
        },

        onWordCancel() {
            this.resetForm('queryWordForm');
            this.uploadBtn = {
                text: '点击上传',
                btn: false,
            };
            this.selectWord = [];
            this.showWordSearch = false
        },

        onSure() {
            if (this.selectImage.length === 0) {
                this.msgError('请选择图片');
                return;
            }
            if (this.multipleChoice && this.selectImage.length > this.maxChoice) {
                this.msgError(`最多只能选择${this.maxChoice}账`);
                return;
            }

            for(let item of this.selectImage) {
                // 获取光标所在位置
                const quill = this.Quill
                const length = quill.getSelection().index;
                // 插入图片  res.data.url为服务器返回的图片地址
                quill.insertEmbed(length, 'image', item)
                // 调整光标到最后
                quill.setSelection(length + 1)
            }
            this.onCancel();
        },

        onVideoSure() {
            if (this.selectVideo.length === 0) {
                this.msgError('请选择视频');
                return;
            }

            for(let item of this.selectVideo) {
                // 获取光标所在位置
                const quill = this.Quill
                const length = quill.getSelection().index;
                quill.insertEmbed(length, 'video', item)
                quill.setSelection(length + 1)
            }
            this.onVideoCancel();
        },

        onAudioSure() {
            if (this.selectAudio.length === 0) {
                this.msgError('请选择语音');
                return;
            }
            for(let item of this.selectAudio) {
                // 获取光标所在位置
                const quill = this.Quill
                const length = quill.getSelection().index;
                quill.insertEmbed(length, 'audio', item)
                // 调整光标到最后
                quill.setSelection(length + 1)
            }
            this.onAudioCancel();
        },
        onWordSure() {
            if (this.selectWord.length === 0) {
                this.msgError('请选择文档');
                return;
            }
            for(let item of this.selectWord) {
                // 获取光标所在位置
                const quill = this.Quill
                const length = quill.getSelection().index;
                quill.insertEmbed(length, 'word', item)
                // 调整光标到最后
                quill.setSelection(length + 1)
            }
            this.onWordCancel();
        },
    },
}
</script>

<style>
.close{
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    font-size: 15px;
    color: #fff;
    border-radius: 50%;
    opacity: 0.8;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    background-color: #606266;
    margin-left: 60px;
}
.play{
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    font-size: 24px;
    color: #fff;
    border-radius: 50%;
//opacity: 0.8;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
//background-color: #606266;
    margin: 25px;
}
.editor,
.ql-toolbar {
    white-space: pre-wrap !important;
    line-height: normal !important;
}
.quill-img {
    display: none;
}

.ql-snow button.ql-audio {
    background-image: url("./audio.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.ql-snow button.ql-word {
    background-image: url("./word.svg");
    background-repeat: no-repeat;
    background-position: center;
}


.ql-snow .ql-tooltip[data-mode='link']::before {
    content: '请输入链接地址:';
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode='video']::before {
    content: '请输入视频地址:';
}
.ql-snow .ql-tooltip[data-mode='video'],
.ql-snow .ql-tooltip[data-mode='link'] {
    left: 0 !important;
    top: 0 !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
    content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
    content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
    content: '32px';
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
    content: '标题1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
    content: '标题2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
    content: '标题3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
    content: '标题4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
    content: '标题5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
    content: '标题6';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
    content: '衬线字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
    content: '等宽字体';
}
</style>
