<template>
    <div>
        <div style="display: flex; flex-wrap: wrap;">
            <div style="display: flex; flex-wrap: wrap;">
                <div v-for="(item, index) in showImage" :key="index" style="margin: 0px 5px;">
                    <div class="close" @click="onDeleteImage(index)">
                        <el-icon class="el-icon-close"/>
                    </div>
                    <el-image
                        style="width: 80px; height: 80px; border-radius: 5px; "
                        lazy
                        :src="item"
                        fit="cover"
                        :preview-src-list="[item]">
                    </el-image>
                </div>
            </div>
            <div>
                <el-button size="mini" type="primary" icon="el-icon-upload" @click="onOpenSelectImage">上传图片
                </el-button>
            </div>
        </div>
        <div v-if="isExplainSize">
            <span style="color: #999999">{{ explainSize }}</span>
        </div>
        <el-dialog
            title="图片库"
            width="68%"
            :visible.sync="showSearch"
            :close-on-click-modal="false"
            append-to-body>
            <div>
                <el-row type="flex" :gutter="20" justify="space-between">
                    <el-col :span="16">
                        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch"
                                 label-width="70px">
                            <el-form-item label="分组" prop="group_id.value">
                                <el-select v-model="queryParams.group_id.value" placeholder="请选择分组" size="small"
                                           clearable>
                                    <el-option
                                        v-for="item in folderOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="图片名称" prop="original_name.value">
                                <el-input v-model="queryParams.original_name.value" placeholder="请输入图片名称"
                                          clearable
                                          size="small"
                                          style="width: 240px" @keyup.enter.native="handleQuery"/>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索
                                </el-button>
                                <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="6">
                        <div style="margin-top: 5px">
                            <el-button size="mini" icon="el-icon-upload" @click="uploadOpen = true" type="primary">
                                上传图片
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div style="display: flex; flex-wrap: wrap;" v-if="dataList.length > 0">
                <div style="margin: 5px 5px;"
                     v-for="(item, index) in dataList"
                     :key="index"
                     @click="onSelectImage(item)">
                    <div v-if="selectImage.length > 0 && selectImage.indexOf(item.url) > -1"
                         class="close">
                        <el-icon class="el-icon-check"/>
                    </div>
                    <el-image
                        style="width: 80px; height: 80px; border-radius: 5px; "
                        lazy
                        :src="item.url"
                        fit="cover">
                    </el-image>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap; justify-content: center" v-else>
                <el-empty description="暂无图片"></el-empty>
            </div>
            <pagination v-show="total > 0" :total="total" :page.sync="pageInfo.page" :limit.sync="pageInfo.limit"
                        @pagination="getList"/>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="onCancel">取 消</el-button>
                    <el-button type="primary" @click="onSure">确 定</el-button>
                </span>
        </el-dialog>

        <el-dialog :title="uploadTitle" :visible.sync="uploadOpen" width="500px" :close-on-click-modal="false"
                   append-to-body>
            <el-form label-width="80px">
                <el-form-item label="分组">
                    <el-select v-model="group_id" placeholder="请选择分组" size="small">
                        <el-option
                            v-for="item in folderOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择图片">
                    <el-upload
                        class="upload-demo"
                        accept=".jpg,.jpeg,.png,.webp,.bmp,.gif,.icon,.ico"
                        :before-upload="beforeUploadVal"
                        list-type="picture"
                        :show-file-list="false"
                        :action="actionUrl"
                        :data="uploadData"
                        :multiple="true"
                        :on-exceed="onExceed"
                        :on-success="onSuccessUpload">
                        <el-button size="mini" icon="el-icon-upload"
                                   :loading="uploadBtn.load" :disabled="uploadBtn.load" type="primary">
                            {{ uploadBtn.text }}
                        </el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="uploadOpen = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {getImageList, uploadRead, uploadFinish, getGroupSelect} from '@/api/system/file'

export default {
    props: {
        isExplainSize: {
            type: Boolean,
            default: true,
        },
        explainSize: {
            type: String,
            default: null
        },
        multipleChoice: {
            type: Boolean,
            default: false
        },
        maxChoice: {
            type: Number,
            default: 1
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
    },

    model: {
        prop: 'value',
        event: 'change'
    },

    data() {
        return {
            dataList: [],
            // 显示搜索条件
            showSearch: false,
            // 查询参数
            queryParams: {
                original_name: {value: undefined, op: 'like'},
                file_type: {value: 'image', op: '='},
                group_id: {value: undefined, op: '='},
            },
            actionUrl: '',
            uploadBtn: {
                text: '点击上传',
                load: false,
            },
            // uploadData: [],
            // uploadIdx: 0,
            uploadData: {},
            uploadKey: '',
            uploadFile: [],
            // 总条数
            total: 0,
            uploadNumber: 0,
            pageInfo: {
                page: 1,
                limit: 50,
                sort: undefined,
                order: undefined,
            },
            selectImage: [],
            showImage: [],
            folderOptions: [],
            group_id: undefined,
            uploadTitle: '上传图片',
            uploadOpen: false,
        }
    },
    watch: {
        value(val, oldVal) {
            this.showImage = val ? val.split(',') : [];
            this.selectImage = val ? val.split(',') : [];
        },
    },
    mounted() {
        if (this.value) {
            this.showImage = this.value.split(',');
            this.selectImage = this.value.split(',');
        }
        this.getGroupsData();
    },
    methods: {
        async getGroupsData() {
            const result = await getGroupSelect({type: 1});
            if (result) {
                this.folderOptions = result.data;
                this.group_id = result.data[0].value;
            }
        },
        async onOpenSelectImage() {
            this.getGroupsData();
            this.getList();
            this.showSearch = true;
        },

        onSelectImage(item) {
            let idx = this.selectImage.indexOf(item.url);
            if (idx > -1) {
                this.selectImage.splice(idx, 1);
            } else {
                if (!this.multipleChoice && this.selectImage.length >= this.maxChoice) {
                    this.msgError('只能选择一张');
                    return;
                }
                this.selectImage.push(item.url);
            }
        },

        // 查询管理员列表
        async getList() {
            const qyparams = {
                ...this.pageInfo,
                ...this.formatQueryParams(this.queryParams),
            }
            const result = await getImageList(qyparams);
            if (result) {
                this.dataList = result.data
                this.total = result.count
            }
        },
        handleQuery() {
            this.pageInfo.page = 1
            this.getList()
        },

        onExceed(files) {
            if(files.length > 10){
                this.msgError('最多只能选择10张图片上传');
                return false;
            }
        },

        async beforeUploadVal(file) {
            if(file.size > 1024 * 1024 * 2) {
                this.msgError(`（${file.name}）图片文件不能大于2M`);
                return false;
            }

            this.uploadBtn = {
                text: '上传中',
                load: true,
            }

            const result = await uploadRead({
                file_name: file.name,
                size: file.size,
                type: file.type,
            });
            if (result) {
                const {data} = result;
                this.uploadFile.push({
                    uid: file.uid,
                    uploadKey: data.key,
                    actionUrl: data.url,
                    uploadData: data.params,
                });
                this.actionUrl = data.url;
                this.uploadData = data.params;
                return true;

            }
        },

        async onSuccessUpload(response, file) {
            let idx = this.uploadFile.findIndex(item => {
                return item.uid === file.uid;
            });
            if (idx > -1) {
                const uploadFile = this.uploadFile[idx];
                const result = await uploadFinish({
                    key: uploadFile.uploadKey,
                    file_name: file.name,
                    url: uploadFile.uploadData.key,
                    group_id: this.group_id,
                });
                if (result) {
                    if (this.uploadFile.length - 1 === idx) {
                        this.uploadFile = [];
                        this.actionUrl = '';
                        this.uploadData = {};
                        this.uploadBtn = {
                            text: '点击上传',
                            load: false,
                        };
                        this.uploadOpen = false;
                        setTimeout(() => {
                            this.msgSuccess('上传成功');
                            this.pageInfo.page = 1;
                            this.getList();
                        }, 500);

                    }
                }
            } else {
                this.msgError('上传失败');
            }
        },

        resetQuery() {
            this.resetForm('queryForm')
            this.handleQuery()
        },

        onCancel() {
            this.resetForm('queryForm');
            this.uploadBtn = {
                text: '点击上传',
                btn: false,
            };
            this.selectImage = [];
            this.showSearch = false
        },

        onSure() {
            if (this.selectImage.length === 0) {
                this.msgError('请选择图片');
                return;
            }
            if (this.multipleChoice && this.selectImage.length > this.maxChoice) {
                this.msgError(`最多只能选择${this.maxChoice}张`);
                return;
            }
            this.showImage = this.selectImage;
            this.$emit('change', this.showImage.length > 0 ? this.showImage.join(',') : '');
            this.onCancel();
        },
        onDeleteImage(index) {
            this.showImage.splice(index, 1);
            this.selectImage.splice(index, 1)
            this.$emit('change', this.showImage.length > 0 ? this.showImage.join(',') : '');
        },
        cancelUpload() {
            this.openImg = false;
        }
    }
}
</script>
<style scoped lang="scss">
.close {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    font-size: 15px;
    color: #fff;
    border-radius: 50%;
    opacity: 0.8;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    background-color: #606266;
    margin-left: 60px;
}
</style>
