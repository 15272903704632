
export const api = {
    menu: {
        adminIndex: '/admin/system.menu/adminIndex',
        index: '/admin/system.menu/index',
        add: '/admin/system.menu/add',
        find: '/admin/system.menu/find',
        edit: '/admin/system.menu/edit',
        delete: '/admin/system.menu/delete',
        status: '/admin/system.menu/status'
    },
    role: {
        authData: '/admin/system.role/authData',
        authGroup: '/admin/system.role/authGroup',
        index: '/admin/system.role/index',
        add: '/admin/system.role/add',
        find: '/admin/system.role/find',
        edit: '/admin/system.role/edit',
        delete: '/admin/system.role/delete',
        status: '/admin/system.role/status',
        selectList: '/admin/system.role/selectList'
    },
    admin: {
        index: '/admin/system.admin/index',
        add: '/admin/system.admin/add',
        find: '/admin/system.admin/find',
        edit: '/admin/system.admin/edit',
        delete: '/admin/system.admin/delete',
        status: '/admin/system.admin/status'
    },
    file: {
        index: '/admin/system.files/index',
        delete: '/admin/system.files/delete',
        getImageList: '/admin/system.files/getImageList',
        uploadRead: '/admin/system.files/uploadRead',
        uploadReads: '/api/uploads/getOssSignature',
        uploadFinish: '/admin/system.files/uploadFinish',
        groupIndex: '/admin/system.files_group/index',
        groupAdd: '/admin/system.files_group/add',
        groupFind: '/admin/system.files_group/find',
        groupEdit: '/admin/system.files_group/edit',
        groupDelete: '/admin/system.files_group/delete',
        groupSelect: '/admin/system.files_group/select',
    },
    config: {
        index: '/admin/system.config/index',
        getConfig: 'admin/ajax/getConfig'
    },
    log: {
        index: '/admin/system.log/index'
    },
    route: {
        initIndex: '/admin/ajax/initIndex'
    },
    login: {
        index: '/admin/passport/index',
        register: '/admin/passport/register',
        userinfo: '/admin/passport/userinfo',
        logout: '/admin/passport/logout',
        update: '/admin/passport/update',
        getCaptcha: '/admin/ajax/getCaptcha'
    },

    tags: {
        index: '/admin/tags.index/index',
        add: '/admin/tags.index/add',
        find: '/admin/tags.index/find',
        edit: '/admin/tags.index/edit',
        delete: '/admin/tags.index/delete',
        list: '/admin/tags.index/list',
    },
    agreement: {
        index: '/admin/agreement.index/index',
        find: '/admin/agreement.index/find',
        edit: '/admin/agreement.index/edit',
    },
    user: {
        index: '/admin/user.index/index',
        balance: '/admin/user.index/balance',
        find: '/admin/user.index/find',
    },
    balance: {
        index: '/admin/balance.index/index',
    },
    orders: {
        index: '/admin/orders.index/index',
        find: '/admin/orders.index/find',
        delete: '/admin/orders.index/delete',
        status: '/admin/orders.index/status',
        export: '/admin/orders.index/export',
    },

    refunds: {
        index: '/admin/refunds.index/index',
        find: '/admin/refunds.index/find',
        delete: '/admin/refunds.index/delete'
    },
    banner: {
        index: '/admin/banner.index/index',
        find: '/admin/banner.index/find',
        edit: '/admin/banner.index/edit',
    },
    dconfig: {
        index: '/admin/config.index/index',
    },
    services: {
        index: '/admin/services.index/index',
        add: '/admin/services.index/add',
        find: '/admin/services.index/find',
        edit: '/admin/services.index/edit',
        delete: '/admin/services.index/delete',
        list: '/admin/services.index/list',
    },
    stores: {
        index: '/admin/stores.index/index',
        add: '/admin/stores.index/add',
        find: '/admin/stores.index/find',
        edit: '/admin/stores.index/edit',
        delete: '/admin/stores.index/delete',
        status: '/admin/stores.index/status',
        list: '/admin/stores.index/list',
    },
    artificers: {
        index: '/admin/artificers.index/index',
        add: '/admin/artificers.index/add',
        find: '/admin/artificers.index/find',
        edit: '/admin/artificers.index/edit',
        delete: '/admin/artificers.index/delete',
        status: '/admin/artificers.index/status',
        line: '/admin/artificers.index/line',
        services: '/admin/artificers.index/services',
        all: '/admin/artificers.index/all',
    },

    coupons: {
        index: '/admin/coupon.index/index',
        add: '/admin/coupon.index/add',
        find: '/admin/coupon.index/find',
        edit: '/admin/coupon.index/edit',
        delete: '/admin/coupon.index/delete',
    },
    cgrants: {
        index: '/admin/cgrant.index/index',
        add: '/admin/cgrant.index/add',
    },
    record: {
        index: '/admin/record.index/index',
        delete: '/admin/record.index/delete',
    },
    withdraws: {
        index: '/admin/withdraws.index/index',
        examine: '/admin/withdraws.index/examine',
        confirm: '/admin/withdraws.index/confirm',
    },
    crecharge: {
        index: '/admin/recharge.index/index',
        add: '/admin/recharge.index/add',
        find: '/admin/recharge.index/find',
        edit: '/admin/recharge.index/edit',
        delete: '/admin/recharge.index/delete',
    },

    comments: {
        index: '/admin/comments.index/index',
        delete: '/admin/comments.index/delete',
        add: '/admin/comments.index/add',
    },
    idfa: {
        index: '/admin/idfa.index/index',
    },
    feedback: {
        index: '/admin/feedback.index/index',
    },
    vip: {
        index: '/admin/vip.index/index',
        add: '/admin/vip.index/add',
        find: '/admin/vip.index/find',
        edit: '/admin/vip.index/edit',
        delete: '/admin/vip.index/delete',
        status: '/admin/vip.index/status',
        record: '/admin/vip.index/record'
    },
    region: {
        index: '/admin/region.index/index',
        add: '/admin/region.index/add',
        find: '/admin/region.index/find',
        edit: '/admin/region.index/edit',
        delete: '/admin/region.index/delete',
        status: '/admin/region.index/status',
    },
    statistics: {
        index: '/admin/statistics.index/index'
    }
}










